import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeviceCompliance from "../DeviceCompliance";
import DeviceRemoval from "../DeviceRemoval";
import { Link } from "react-router-dom";
import { ROUTE_LIST } from "../../routes";
import streaming_devices from "./streaming-devices.json";
import tv_types from "./tv-types.json";
import { UpdateStreamingDevice } from "../../services/backend";
import StreamingDevice from "./StreamingDevice";
import picklistRooms from "../../../picklistValuesGetter/picklists/room_placement.json";
import picklistBrands from "../../../picklistValuesGetter/picklists/brand.json";

const smart_or_internet = {
  1: "SmartTV",
  2: "Internet connected TV",
};

const DashboardTelevisions = (props) => {
  const [televisions, setTelevisions] = useState([]);
  const [devices, setDevices] = useState([]);

  const handleDeviceComplianceUpdate = (bool, tvid) => {
    // update tv compliance
    const tvs = [...televisions];
    tvs.find((tv) => tv?.Name === tvid).is_compliant = !!bool;
    setTelevisions(tvs);
  };

  const findDevice = (tvid) => {
    const device = devices.find((d) => d.television_id === tvid);
    return device;
  };

  const getRoomPlacementLabel = (room_placement) => {
    return picklistRooms.find((item) => parseInt(item.value) === parseInt(room_placement)).label;
  };

  const getBrandLabel = (brand) => {
    return picklistBrands.find((item) => parseInt(item.value) === parseInt(brand)).label;
  };

  useEffect(() => {
    setTelevisions(props.televisions);
  }, [props.televisions]);

  useEffect(() => {
    setDevices(props.devices);
  }, [props.devices]);

  useEffect(() => {
    const isCompliant = televisions.reduce((x, y) => (x += !!y.is_compliant ? 1 : 0), 0) === televisions.length;
    props?.onComplianceUpdate(isCompliant);
  }, [televisions]);

  return (
    <div className="list-televisions">
      {/* 2024-02-01 :: requested by Carolina to remove */}
      {/* <Link to={ROUTE_LIST.ADD_TV} className="bg-transparent cursor-pointer text-lg border-0 SamsungOneNormal text-blue-700 hover:underline">
        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
        Add a TV
      </Link> */}

      {televisions.map((tv, idx) => (
        <div key={tv.Id} className="television grid grid-cols-7 p-2 py-3 bg-white rounded-full my-2 items-center">
          {/* <div className="px-4">{idx + 1}</div> */}
          <div className="col-span-3">
            <span className="inline-block w-8 text-center">{idx + 1}</span>
            {getRoomPlacementLabel(tv.room_placement)}
          </div>
          {/* <div className="text-xs pr-4">{tv?.Name}</div> */}
          <div className="">{getBrandLabel(tv.brand)}</div>
          <div className="">{smart_or_internet[tv.smart_or_internet]}</div>
          <div className="">
            <StreamingDevice tv={tv} />
          </div>
          <div className="">
            <DeviceCompliance device={findDevice(tv.Id)} onUpdate={(bool) => handleDeviceComplianceUpdate(bool, tv?.Name)} />
          </div>
          {/* <div className="flex-shrink px-4">
            <DeviceRemoval tvId={tv?.Name} />
          </div> */}
        </div>
      ))}
    </div>
  );
};

DashboardTelevisions.propTypes = {
  televisions: PropTypes.arrayOf(PropTypes.object).isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  onComplianceUpdate: PropTypes.func,
};

export default DashboardTelevisions;
