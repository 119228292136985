import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useQueryState = (key, defaultValue) => {
  const history = useHistory();
  const location = useLocation();

  const getQueryValue = (key, defaultValue) => {
    const params = new URLSearchParams(location.search);
    return params.get(key) || defaultValue;
  };

  const [state, setState] = useState(() => getQueryValue(key, defaultValue));

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (state !== defaultValue) {
      params.set(key, state);
    } else {
      params.delete(key);
    }
    history.replace({ search: params.toString() });
  }, [key, state, defaultValue, history, location.search]);

  return [state, setState];
};

export default useQueryState;
