import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { UpdateProfile } from "../../services/backend";
import Popup from "reactjs-popup";
import us_states from "./us-states.json";
import { isEmpty, isNotEmpty, isValidEmail, isValidPhoneNumber } from "../../utility";
import { GlobalContext } from "../../store/global-store";
import Cookies from "js-cookie";
import global_config from "../../global.config.json";
import EVENTS from "../../store/events.json";
import jwt_decode from "jwt-decode";
import EmailInput from "../form-inputs/EmailInput";
import RequiredIndicator from "../form-inputs/RequiredIndicator";
import picklistStates from "../../../picklistValuesGetter/picklists/state_province.json";
import picklistPhoneTimes from "../../../picklistValuesGetter/picklists/preferred_phone_time.json";
import picklistTimezones from "../../../picklistValuesGetter/picklists/timezone.json";

const update_msg = {
  success: "Your profile has been updated.",
  error: "There was an error.",
};

const MyProfile = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [submitModalOpened, setSubmitModalOpened] = useState(false);
  const [updateProfileMsg, setUpdateProfileMsg] = useState(update_msg.success);
  const [isTouched, setIsTouched] = useState(false);
  const [formValues, setFormValues] = useState({
    // my name is
    FirstName: { value: state?.user?.FirstName || "", required: true, valid: true, validate: isNotEmpty },
    LastName: { value: state?.user?.LastName || "", required: true, valid: true, validate: isNotEmpty },
    nickname: { value: state?.user?.nickname || "", required: false, valid: true, validate: isNotEmpty },

    // contact pref
    preferred_phone_time: { value: state?.user?.preferred_phone_time, required: false, valid: true, validate: isNotEmpty },
    timezone: { value: state?.user?.timezone || "", required: false, valid: true, validate: isNotEmpty },

    // contact method
    consent_to_call: { value: !!state?.user?.consent_to_call, required: false, valid: true },
    sms_consent: { value: !!state?.user?.sms_consent, required: false, valid: true },

    // contact details
    Email: { value: state?.user?.Email || "", required: true, valid: true, validate: isValidEmail },
    secondary_email: { value: state?.user?.secondary_email || "", required: false, valid: true, validate: isValidEmail },
    MobilePhone: { value: state?.user?.MobilePhone || "", required: true, valid: true, validate: isValidPhoneNumber },

    // address
    street_1: { value: state?.user?.street_1 || "", required: true, valid: true, validate: isNotEmpty },
    street_2: { value: state?.user?.street_2 || "", required: false, valid: true, validate: isNotEmpty },
    city: { value: state?.user?.city || "", required: true, valid: true, validate: isNotEmpty },
    zip_code: { value: state?.user?.zip_code || "", required: true, valid: true, validate: isNotEmpty },
    state_province: { value: state?.user?.state_province || "", required: true, valid: true, validate: isNotEmpty },
  });

  const updateValue = (field, value) => {
    // console.log("updateValue", field, value);
    const isValid =
      typeof value === "boolean" ||
      (!!formValues?.[field]?.required && !!formValues?.[field]?.validate(value)) ||
      (!!!formValues?.[field]?.required && typeof value !== "boolean" && isEmpty(value)) ||
      (!!!formValues?.[field]?.required && !!formValues?.[field]?.validate(value));
    setFormValues({ ...formValues, [field]: { ...formValues[field], value, valid: isValid } });
    setIsTouched(true);
  };

  const submitForm = () => {
    setShowErrorMessage(false);
    setIsLoading(true);
    const payload = Object.keys(formValues).reduce((x, k) => {
      x[k] = formValues?.[k]?.value !== null ? formValues?.[k]?.value : null;
      if (formValues?.[k]?.value !== "" && ["preferred_phone_time", "timezone"].includes(k)) {
        x[k] = parseInt(formValues?.[k]?.value);
      }
      return x;
    }, {});
    UpdateProfile(payload)
      .then((res) => {
        const access_token = res?.access_token;

        const user = jwt_decode(access_token);

        Cookies.set(global_config.session.name, access_token);

        dispatch({
          type: EVENTS.UPDATE_USER,
          payload: user,
        });

        setIsLoading(false);
        setSubmitModalOpened(true);
        setSubmitEnabled(false);
        setUpdateProfileMsg(update_msg.success);
        setIsTouched(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setSubmitModalOpened(false);
        setUpdateProfileMsg(`${update_msg.error} : ${err}`);
      });
  };

  useEffect(() => {
    const isValid = Object.keys(formValues).reduce((x, k) => x && !!formValues[k].valid, true);
    setSubmitEnabled(isValid);
  }, [formValues]);

  return (
    <div className="my-profile grid grid-cols-2 gap-3 mt-4">
      <div className="form-container bg-white p-4">
        <div className="name-form flex flex-col">
          <div className="SamsungSharpSansNormal">My name is...</div>
          <div className="flex flex-row items-center space-x-2 my-3">
            <input
              value={formValues.FirstName?.value}
              onChange={(e) => updateValue("FirstName", e.target.value)}
              type="text"
              placeholder="First name"
              className={`py-2 px-3 rounded-md bg-gray-100 border-0`}
              disabled={isLoading}
            />
            <RequiredIndicator />
            <input
              value={formValues.LastName?.value}
              onChange={(e) => updateValue("LastName", e.target.value)}
              type="text"
              placeholder="Family name"
              className={`py-2 px-3 rounded-md bg-gray-100 border-0`}
              disabled={isLoading}
            />
            <RequiredIndicator />
          </div>
        </div>
        <div className="name-form flex flex-col my-2">
          <div className="SamsungSharpSansNormal">But you can call me...</div>
          <input
            type="text"
            value={formValues.nickname?.value}
            onChange={(e) => updateValue("nickname", e.target.value)}
            placeholder="nickname"
            className="py-2 px-3 mt-3 rounded-md border border-solid border-gray-500"
            disabled={isLoading}
          />
        </div>
      </div>

      <div className="form-container bg-white p-4">
        <div className="SamsungSharpSansNormal">Contact Preferences</div>

        <div className="flex flex-row items-center my-4">
          <label htmlFor="can_call">My preferred call time is:</label>
          <select
            className="border border-solid border-gray-500 ml-2 py-2 px-3 rounded-md"
            id="preferred_phone_time"
            disabled={!formValues.consent_to_call?.value || isLoading}
            defaultValue={formValues.preferred_phone_time?.value}
            onChange={(e) => updateValue("preferred_phone_time", e.target.value)}
          >
            <option value="">Select time</option>
            {picklistPhoneTimes.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <select
            className="border border-solid border-gray-500 ml-2 py-2 px-3 rounded-md"
            defaultValue={formValues.timezone?.value}
            disabled={isLoading}
            onChange={(e) => updateValue("timezone", e.target.value)}
          >
            <option value="">Select timezone</option>
            {picklistTimezones.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>

        <div className="SamsungSharpSansNormal mt-4 mb-2">
          Contact Method <RequiredIndicator />:
        </div>
        <div className="flex flex-row items-center">
          <input type="checkbox" checked readOnly className="mr-2" id="contact_email" disabled={isLoading} />
          <label htmlFor="contact_email" className="flex-1">
            EMAIL
          </label>
          <input
            type="checkbox"
            checked={formValues.consent_to_call?.value}
            onChange={(e) => updateValue("consent_to_call", e.target.checked)}
            className="mr-2"
            id="contact_phone"
            disabled={isLoading}
          />
          <label htmlFor="contact_phone" className="flex-1">
            PHONE CALL
          </label>
          <input
            type="checkbox"
            checked={formValues.sms_consent?.value}
            onChange={(e) => updateValue("sms_consent", e.target.checked)}
            checked={formValues.sms_consent?.value}
            className="mr-2"
            id="contact_sms"
            disabled={isLoading}
          />
          <label htmlFor="contact_sms" className="flex-1">
            Text Message
          </label>
        </div>
      </div>

      <div className="form-container bg-white p-4">
        <div className="SamsungSharpSansNormal mb-2">Contact details</div>
        <div className="grid grid-cols-2 gap-2 items-center">
          <label htmlFor="primary_email">
            PRIMARY EMAIL
            <RequiredIndicator />
          </label>
          <EmailInput value={formValues.Email?.value} onChange={(val) => updateValue("Email", val)} required={formValues.Email?.required} />
          <label htmlFor="secondary_email">SECONDARY EMAIL</label>
          <EmailInput value={formValues.secondary_email?.value} onChange={(val) => updateValue("secondary_email", val)} required={formValues.secondary_email?.required} />
          {/* <label htmlFor="samsung_rewards">SAMSUNG REWARDS</label>
          <input type="text" value={state?.user?.samsung_rewards_id} className="py-2 px-3 rounded-md border border-solid border-gray-500" id="samsung_rewards_id" disabled={true} /> */}
          <label htmlFor="primary_contact_phone">
            CONTACT PHONE <RequiredIndicator />
          </label>
          <input
            type="text"
            value={formValues.MobilePhone?.value}
            onChange={(e) => updateValue("MobilePhone", e.target.value)}
            className={`${invalidFields.includes("MobilePhone") && "border-red-600"} py-2 px-3 rounded-md border border-solid border-gray-500`}
            id="primary_contact_phone"
            disabled={isLoading}
          />
        </div>
      </div>

      <div className="form-container bg-white p-4">
        <div className="SamsungSharpSansNormal mb-2">Addresses</div>
        <div className="grid grid-cols-2 gap-2 items-center">
          <label htmlFor="address_street_1">
            STREET 1 <RequiredIndicator />
          </label>
          <input
            type="text"
            value={formValues.street_1?.value}
            onChange={(e) => updateValue("street_1", e.target.value)}
            placeholder="Address 1"
            className="py-2 px-3 rounded-md border border-solid border-gray-500"
            id="address_street_1"
            disabled={isLoading}
          />
          <label htmlFor="address_street_2">STREET 2</label>
          <input
            type="text"
            value={formValues.street_2?.value}
            onChange={(e) => updateValue("street_2", e.target.value)}
            placeholder="Address 2"
            className="py-2 px-3 rounded-md border border-solid border-gray-500"
            id="address_street_2"
            disabled={isLoading}
          />
          <label htmlFor="address_city">
            CITY <RequiredIndicator />
          </label>
          <input
            type="text"
            value={formValues.city?.value}
            onChange={(e) => updateValue("city", e.target.value)}
            placeholder="City name"
            className="py-2 px-3 rounded-md border border-solid border-gray-500"
            id="address_city"
            disabled={isLoading}
          />
          <label htmlFor="address_zipcode">
            ZIPCODE <RequiredIndicator />
          </label>
          <input
            type="text"
            value={formValues.zip_code?.value}
            onChange={(e) => updateValue("zip_code", e.target.value)}
            className="py-2 px-3 rounded-md border border-solid border-gray-500"
            id="address_zipcode"
            disabled={isLoading}
          />
          <label htmlFor="address_state">
            STATE/PROVINCE <RequiredIndicator />
          </label>{" "}
          <select
            value={formValues.state_province?.value}
            onChange={(e) => updateValue("state_province", e.target.value)}
            className="py-2 px-3 rounded-md border border-solid border-gray-500"
            disabled={isLoading}
          >
            <option value="">Select State</option>
            {picklistStates.map((state) => (
              <option key={state.value} value={state.value}>
                {state.label}
              </option>
            ))}
          </select>
          {/* <input
            type="text"
            value={formValues.state_province?.value}
            onChange={(e) => updateValue("state_province", e.target.value)}
            placeholder="State name / Province name"
            className="py-2 px-3 rounded-md border border-solid border-gray-500"
            id="address_state"
          /> */}
        </div>
      </div>

      <div className="col-span-2 m-auto flex flex-col items-center">
        <button
          className={` ${
            !submitEnabled || isLoading || !isTouched ? "cursor-not-allowed bg-gray-300 border-0" : "cursor-pointer bg-blue-800 text-white"
          } mt-6 w-28 max-w-md py-2 px-4 rounded-full no-underline`}
          disabled={!submitEnabled || isLoading || !isTouched}
          onClick={submitForm}
        >
          Save
        </button>
        {showErrorMessage && <p className="text-base text-red-400">Please fill in the required fields.</p>}
      </div>

      {isLoading && (
        <div className="loading-indicator fixed top-0 right-0 left-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
          <FontAwesomeIcon icon={faSpinner} spin pulse className="text-4xl text-white z-50" />
        </div>
      )}

      <Popup modal open={submitModalOpened} onClose={() => setSubmitModalOpened(false)}>
        <div className="modal bg-white p-12 border border-black">
          <FontAwesomeIcon icon={faTimes} className="absolute right-0 top-0 m-5 cursor-pointer" onClick={() => setSubmitModalOpened(false)} />
          <div className="content width-full text-center">{updateProfileMsg}</div>
        </div>
      </Popup>
    </div>
  );
};

export default MyProfile;
