/** @jsx jsx */
import { jsx } from "@emotion/react";

import { css } from "@emotion/react";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import ComplianceMessage from "../../components/ComplianceMessage";
import { Loader } from "../../components/Loader";
import WelcomeUserInfo from "../../components/WelcomeUserInfo";
import DashboardTab from "../../components/dashboard/DashboardTab";
import DashboardTelevisions from "../../components/dashboard/DashboardTelevisions";
import HouseholdMembers from "../../components/dashboard/HouseholdMembers";
import MyProfile from "../../components/dashboard/MyProfile";
import global_config from "../../global.config.json";
import { ROUTE_LIST } from "../../routes";
import { GetTelevisions } from "../../services/backend";
import EVENTS from "../../store/events.json";
import { GlobalContext } from "../../store/global-store";
import { Rewards } from "../rewards/Rewards";

const dashboard_tabs = [
  {
    label: "My TVs",
    path: "/dashboard/televisions/",
  },
  {
    label: "My Household Members",
    path: "/dashboard/members/",
  },
  {
    label: "My Profile",
    path: "/dashboard/profile/",
  },
  {
    label: "Rewards",
    path: "/dashboard/rewards/",
  },
];

const Dashboard = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const [televisions, setTelevisions] = useState([]);
  const [devices, setDevices] = useState([]);
  const [isCompliant, setIsCompliant] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const handleComplianceUpdate = (bool) => {
    setIsCompliant(bool);
  };

  useEffect(() => {
    setIsLoading(true);
    GetTelevisions()
      .then((res) => {
        setTelevisions(res?.televisions || []);
        setDevices(res?.devices || []);
        setIsLoading(false);
      })
      .catch((error) => {
        Cookies.remove(global_config.session.name);
        setTelevisions([]);
        setDevices([]);
        setIsLoading(false);
        if (error.response.status === 401) {
          dispatch({
            type: EVENTS.UPDATE_USER,
            payload: null,
          });
          history.push(ROUTE_LIST.HOME);
        }
      });
  }, []);

  if (!dashboard_tabs.map((tab) => tab.path).includes(location.pathname)) {
    return <Redirect to={dashboard_tabs[0].path} />;
  }

  return (
    <div className="bg-gray-100 p-6">
      {!!!state.isLoading && <WelcomeUserInfo />}

      <ComplianceMessage compliant={!!isCompliant} />

      <div className="">
        <div className="flex flex-row items-center justify-center my-12">
          {dashboard_tabs.map((tab) => (
            <DashboardTab key={tab.path} to={tab.path} active={tab.path === location.pathname}>
              {tab.label}
            </DashboardTab>
          ))}
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Switch>
            <Route path={dashboard_tabs[0].path}>
              {isLoading && (
                <Loader />
                // <div className="items-center text-2xl text-center p-4">
                //   <FontAwesomeIcon icon={faSpinner} spin pulse />
                // </div>
              )}
              {!isLoading && <DashboardTelevisions televisions={televisions} devices={devices} onComplianceUpdate={handleComplianceUpdate} />}
            </Route>
            <Route path={dashboard_tabs[1].path}>
              <HouseholdMembers />
            </Route>
            <Route path={dashboard_tabs[2].path}>
              <MyProfile />
            </Route>
            <Route path={dashboard_tabs[3].path}>
              <Rewards />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
