import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation, Redirect, matchPath } from "react-router-dom";
import Home from "./pages/home/home";
import Dashboard from "./pages/dashboard/dashboard";
import Inbox from "./pages/inbox/inbox";
import CancelMembership from "./pages/cancel-membership/cancel-membership";
import EVENTS from "./store/events.json";
import { GlobalContext } from "./store/global-store";
import FAQ from "./pages/faq/FAQ";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsOfService from "./pages/terms-of-service/TermsOfService";
import AddTelevision from "./pages/add-tv/AddTelevision";
import CancelMembershipSurvey from "./pages/cancel-membership/CancelMembershipSurvey";
import Coregister from "./pages/coreg/Coregister";
import Cookies from "js-cookie";
import global_config from "./global.config.json";
import jwt_decode from "jwt-decode";
import Authorize from "./pages/authorize/Authorize";
import ErrorPage from "./pages/general/Error";
import Register from "./pages/general/Register";
import Userid from "./pages/general/Userid";
import UseridLogin from "./pages/general/UseridLogin";

export const ROUTE_LIST = {
  HOME: "/",
  DASHBOARD: "/dashboard/",
  INBOX: "/inbox/",
  CANCEL_MEMBERSHIP: "/cancel-membership/",
  TERMS_OF_SERVICE: "/terms-of-service/",
  PRIVACY_POLICY: "/privacy-policy/",
  FAQ: "/faq/",
  // ADD_TV: "/add-television/",
  CANCEL_MEMBERSHIP_SURVEY: "/cancel-membership/survey/",
  COREGISTER: "/coreg/",
  AUTHORIZE: "/authorize/",
  ERROR: "/error/",
  REGISTER: "/register/",

  USERID: "/userid/",
  USERIDLOGIN: "/userid-login/",
};

const authenticated_routes = [ROUTE_LIST.DASHBOARD, ROUTE_LIST.CANCEL_MEMBERSHIP, ROUTE_LIST.CANCEL_MEMBERSHIP_SURVEY, ROUTE_LIST.ADD_TV, ROUTE_LIST.INBOX];

const bypassRedirect = (path) => {
  const bypass = [
    ROUTE_LIST.REGISTRATION,
    ROUTE_LIST.AUTHORIZE,
    ROUTE_LIST.ERROR,
    ROUTE_LIST.REGISTER,
    ROUTE_LIST.USERID,
    ROUTE_LIST.USERIDLOGIN,
    ROUTE_LIST.FAQ,
    ROUTE_LIST.TERMS_OF_SERVICE,
    ROUTE_LIST.CANCEL_MEMBERSHIP,
  ];
  return bypass.includes(path);
};

export default function Routes() {
  const { state, dispatch } = useContext(GlobalContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const isAuthenticatedRoute = authenticated_routes.some(route =>
    matchPath(location.pathname, {
      path: route,
      exact: false,  // Change to false if you want to match sub-paths as well
      strict: false // Change to true if you want strict matching of trailing slashes
    })
  );

  useEffect(() => {
    const access_token = Cookies.get(global_config.session.name);
    if (access_token) {
      const decoded = jwt_decode(access_token);
      const user = decoded;
      dispatch({
        type: EVENTS.UPDATE_USER,
        payload: user,
      });
    }
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      console.log('state.user:', state.user);
      console.log('location.pathname:', location.pathname);
      if (state.user) {
        if (location.pathname === "/") {
          history.push(ROUTE_LIST.DASHBOARD);
        }
      } else {
        // if (!bypassRedirect(location.pathname) || authenticated_routes.includes(location.pathname)) {
        if (isAuthenticatedRoute) {
          history.push(ROUTE_LIST.HOME);
        }
      }
    }
  }, [isLoaded, state.user, isAuthenticatedRoute]);

  return (
    <div className="routes-container flex-grow">
      <Switch>
        {/* PRIVATE */}
        <Route path={ROUTE_LIST.DASHBOARD} component={Dashboard} />
        <Route exact path={ROUTE_LIST.INBOX} component={Inbox} />
        {/* <Route exact path={ROUTE_LIST.ADD_TV} component={AddTelevision} /> */}
        <Route exact path={ROUTE_LIST.CANCEL_MEMBERSHIP_SURVEY} component={CancelMembershipSurvey} />
        <Route exact path={ROUTE_LIST.CANCEL_MEMBERSHIP} component={CancelMembership} />

        {/* PUBLIC */}
        <Route exact path={ROUTE_LIST.TERMS_OF_SERVICE} component={TermsOfService} />
        <Route exact path={ROUTE_LIST.PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route exact path={ROUTE_LIST.FAQ} component={FAQ} />
        <Route exact path={ROUTE_LIST.COREGISTER} component={Coregister} />
        <Route exact path={ROUTE_LIST.HOME} component={Home} />
        <Route exact path={ROUTE_LIST.AUTHORIZE} component={Authorize} />
        <Route exact path={ROUTE_LIST.ERROR} component={ErrorPage} />
        <Route exact path={ROUTE_LIST.REGISTER} component={Register} />

        <Route exact path={ROUTE_LIST.USERID} component={Userid} />
        <Route exact path={ROUTE_LIST.USERIDLOGIN} component={UseridLogin} />
        <Route path="*">
          <ErrorPage />
        </Route>
      </Switch>
    </div>
  );
}
