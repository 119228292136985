import React from "react";
import PropTypes from "prop-types";
import { faExclamationTriangle, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ComplianceMessage = (props) => {
  const { compliant } = props;

  if (!compliant) {
    return (
      <div className="compliance-message is-not-compliant SamsungOneNormal my-4 bg-white rounded-full py-2 px-3 flex flex-row items-center">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-yellow-500 mr-2" />
        Oh no! At least one of your meters is not online! Make sure they are plugged in and connected to your Wifi to continue earning rewards.
      </div>
    );
  }

  return (
    <div className="compliance-message is-compliant SamsungOneNormal my-4 bg-white rounded-full py-2 px-3 flex flex-row items-center">
      <FontAwesomeIcon icon={faThumbsUp} className="text-green-500 mr-2" />
      <b className="mr-1">Great Job!</b> You’re on track. Just make sure to keep your tablets by your TVs, and to check in when asked.
    </div>
  );
};

ComplianceMessage.propTypes = {
  compliant: PropTypes.bool.isRequired,
};

export default ComplianceMessage;
