import React from "react";
import Slider from "react-slick";
import faqData from "./faq.data.json";
import faqConfig from "./faq.config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="next-arrow absolute left-0 top-1/2 z-10 text-5xl opacity-50 hover:opacity-70 -ml-14 -mt-10 cursor-pointer" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronCircleLeft} />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="prev-arrow absolute right-0 top-1/2 z-10 text-5xl opacity-50 hover:opacity-70 -mr-14 -mt-10 cursor-pointer" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronCircleRight} />
    </div>
  );
};

const settings = {
  ...faqConfig,
  slidesToScroll: faqData.length,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

export default function FAQ() {
  return (
    <div className="faq carousel lg:w-2/3 my-4 mx-auto relative">
      <h2 className="SamsungSharpSansBold">FAQ</h2>
      <Slider {...settings}>
        {(faqData || []).map((faq) => (
          <div key={faq.id}>
            <div className="faq-item p-4">
              <div className="faq-title SamsungSharpSansBold py-4">{faq.title}</div>
              <div className="faq-description" dangerouslySetInnerHTML={{ __html: faq.description }}></div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="text-center my-6">
        <Link to="/faq" className="faq-link bg-black border-0 py-2 px-4 rounded-full text-white no-underline inline-block">
          See all FAQ
        </Link>
      </div>
    </div>
  );
}
