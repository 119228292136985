import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import SamsungBannerMark from "../../assets/images/samsung-mark.svg";
import { ROUTE_LIST } from "../../routes";
import { GlobalContext } from "../../store/global-store";
import LoginForm from "../login-form/login-form";

import "./home-banner.scss";

const HomeBanner = () => {
  const { state } = useContext(GlobalContext);

  const renderUserInfo = () => {
    const name = [state.user?.FirstName, state.user?.LastName].join(" ");
    return (
      <div className="my-12 text-lg">
        <p>Welcome {name}!</p>
        <Link to={ROUTE_LIST.DASHBOARD} className="text-white text-sm no-underline SamsungOneNormal">
          Go to dashboard <FontAwesomeIcon icon={faChevronRight} className="text-xs ml-1" />
        </Link>
      </div>
    );
  };

  return (
    <div className="home-banner relative flex">
      <div className="login-form-container SamsungSharpSansBold text-3xl text-white p-12 py-20 pr-16 -mr-14 z-10 flex flex-col">
        <div className="flex-grow leading-relaxed">
          Welcome to Samsung’s
          <br />
          TV and You Community
          {!!!state?.isLoading && state.user && renderUserInfo()}
        </div>
        <div className="flex-shrink">{!state.user && <LoginForm />}</div>
      </div>

      <div className="banner-bg-image flex-1 relative z-0">
        <div className="banner-mark-container absolute right-0 bottom-0 bg-white bg-opacity-80 pr-4 pb-2 pt-10 pl-10 rounded-tl-full">
          <img src={SamsungBannerMark} className="banner-mark" />
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
