/** @jsx jsx */
import { css, jsx } from "@emotion/react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useEffect, useState } from "react";
import { Loader } from "../../components/Loader";
import { GetRewards } from "../../services/backend";

const LIMIT = 5;

const Link = (props) => {
  const { text, showIcon = true, color = "#555" } = props;
  return (
    <a
      href="https://www.samsung.com/us/web/account/my-rewards/"
      target="_blank"
      css={css`
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        color: ${color};
        &:hover {
          text-decoration: underline;
        }
      `}
    >
      {text}
      {showIcon && (
        <OpenInNewIcon
          css={css`
            font-size: 0.9rem;
            margin-top: 2px;
          `}
        />
      )}
    </a>
  );
};

export const Rewards = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [rewards, setRewards] = useState([]);

  const formatCurrency = (total = 0) => {
    return new Intl.NumberFormat("en-US").format(total);
  };

  useEffect(() => {
    GetRewards(LIMIT)
      .then((res) => {
        setRewards(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Divider />
      <h1>Rewards</h1>
      <p>
        This shows the last {LIMIT} rewards sent to your <Link text="Samsung Rewards" showIcon color="#00f" /> account.
      </p>

      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 2rem;
          margin: 1.5rem auto;
          & th {
            font-weight: 600;
          }
          & tfoot th,
          & tfoot td {
            font-weight: 600;
            font-size: 1rem;
          }
        `}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400, maxWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell component="th">Date</TableCell>
                <TableCell component="th" align="right" style={{ width: 70 }}>
                  Total Sent
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rewards.map((reward) => (
                <TableRow key={reward["Id"]} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>{moment(reward["CreatedDate"]).format("LL")}</TableCell>
                  <TableCell align="right">{formatCurrency(reward["amount__c"] ?? 0)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter
              css={css`
                border-top: 1px solid #ccc;
              `}
            >
              <TableRow>
                <TableCell component="th" align="right">
                  Total
                </TableCell>
                <TableCell align="right">{formatCurrency(rewards.reduce((total, reward) => (total += reward["amount__c"] ?? 0), 0))}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <Divider />
      <p>
        <Link text="Open My Samsung Rewards" />
      </p>
    </div>
  );
};
