import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import CommentIcon from "../../assets/images/comment-icon.svg";
import EVENTS from "../../store/events.json";
import { GlobalContext } from "../../store/global-store";

import { isValidEmail } from "../../utility";

import "./message-form.scss";

const MessageForm = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleMessageSend = (e) => {
    dispatch({
      type: EVENTS.UPDATE_MESSAGE_SENT,
      payload: true,
    });
  };

  return (
    <div className="message-form absolute right-0 top-0 -mt-6 -mr-2 z-30">
      <div className="comment-button custom-bg-blue cursor-pointer shadow-md" onClick={(e) => setShowMessageDialog(!showMessageDialog)}>
        <img src={CommentIcon} />
      </div>
      {showMessageDialog && (
        <div className="message-dialog flex flex-col absolute right-full bottom-full -m-2 border border-solid border-gray-200 p-4 shadow-md bg-white w-64">
          <div className="message-header flex-shrink whitespace-nowrap flex items-center">
            <span className="helper-icon text-2xl mb-1.5">☺︎</span>
            <span className="helper-message relative custom-bg-blue text-sm text-white py-1 px-4 rounded-full ml-3">We are happy to help!</span>
            <FontAwesomeIcon icon={faTimes} className="absolute right-0 mx-5 cursor-pointer" onClick={(e) => setShowMessageDialog(false)} />
          </div>

          <div className="message-body pt-4 flex flex-col font-bold">
            {!state.msgSent && (
              <>
                Your email
                <input
                  type="email"
                  placeholder="Enter your email here"
                  className="bg-transparent border-0 border-b border-solid border-gray-400 py-2 outline-none focus:outline-none text-md"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <textarea
                  rows="4"
                  placeholder="Your message goes here."
                  className="message-content bg-gray-200 p-2 mt-4 border-0 SamsungOneNormal focus:outline-none"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </>
            )}

            {state.msgSent && (
              <>
                <p className="text-center font-normal">Thank you! We've received your message and one of our team members will be in touch with you very shortly.</p>
                <p className="text-center font-normal">Thank you for your interest in the TV and You community!</p>
              </>
            )}
          </div>

          {!state.msgSent && (
            <div className="message-footer mt-4 text-right">
              <button
                className={`${!isValidEmail(email) || !message ? "cursor-not-allowed" : "cursor-pointer"} bg-blue-800 text-white py-1 px-4 rounded-full cursor-pointer`}
                onClick={handleMessageSend}
                disabled={!isValidEmail(email) || !message}
              >
                Send
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageForm;
