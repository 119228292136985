import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Routes from "./routes";

const theme = createTheme({
  typography: {
    fontFamily: '"SamsungOne", arial, sans-serif',
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="container mx-auto flex flex-col h-full">
          <Header />
          <Routes />
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
