import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="h-full">
      {/* <h1 className="my-4 text-2xl SamsungSharpSansBold">Privacy Policy</h1> */}
      <iframe src="https://www.samsung.com/us/account/privacy-policy/" className="w-full h-full border-0"></iframe>
    </div>
  );
};

export default PrivacyPolicy;
