import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../store/global-store";
import { Login } from "../../services/backend";

const LoginForm = () => {
  const { state } = useContext(GlobalContext);

  const handleLogin = (e) => {
    Login("login");
  };

  return (
    <div className="login-form text-base">
      <button
        type="submit"
        onClick={handleLogin}
        className="login-button bg-transparent rounded-full w-full border border-solid border-white text-white py-3 text-xl cursor-pointer"
        disabled={!!state?.isLoading}
      >
        Login to your Samsung Account
      </button>
    </div>
  );
};

export default LoginForm;
