import React from "react";
import useQueryState from "../../hooks/useQueryState";

const ERRORS = {
  CONTACT_NOT_FOUND:
    "Access to this program is by invitation only, and your Id was not part of the invitation list. If you wish to be considered for participation, please send an email to support@tvandyou.tv.",
  CONTACT_DEACTIVATED: "Sorry, your membership has been deactivated.",
  OAUTH_EXCEPTION: "OAUTH_EXCEPTION",
  OAUTH_ERROR: "OAUTH_ERROR",
  USERID_ERROR: "USERID_ERROR",
  404: "Page not found.",
};

const ErrorPage = () => {
  const [errorCode] = useQueryState('code', 404);
  const [message] = useQueryState('message', null);
  return <div className="error-message leading-loose text-2xl text-center w-full h-full flex items-center justify-center">{message ?? ERRORS[errorCode]}</div>;
};

export default ErrorPage;
