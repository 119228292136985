import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import SamsungLogo from "../../assets/images/samsung-logo.svg";
import { GlobalContext } from "../../store/global-store";
import envelopeIcon from "../../assets/images/envelope-icon.svg";
import { ROUTE_LIST } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import global_config from "../../global.config.json";
import EVENTS from "../../store/events.json";

const Header = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const history = useHistory();

  const handleLogout = () => {
    Cookies.remove(global_config.session.name);
    dispatch({
      type: EVENTS.UPDATE_USER,
      payload: null,
    });
    history.push(ROUTE_LIST.HOME);
  };

  return (
    <div className="header container mx-auto py-4 flex-shrink flex flex-row items-center">
      <div className="flex-grow flex flex-row items-center">
        <Link to={state.user ? ROUTE_LIST.DASHBOARD : "/"} className={`header-logo ${state.user ? "flex-shrink mr-6" : "flex-grow"} no-underline`}>
          <img src={SamsungLogo} />
        </Link>
        <Link to={state.user ? ROUTE_LIST.DASHBOARD : "/"} className="tv-and-you-header flex-shrink no-underline SamsungSharpSansBold text-blue-800">
          {state.user ? "TV and You Home" : "TV and You"}
        </Link>
      </div>
      {state.user && (
        <div className="flex-shrink flex flex-row items-center">
          {/* <Link to={ROUTE_LIST.INBOX} className="mx-4 relative">
            <img src={envelopeIcon} />
            <div className="w-2 h-2 rounded-full bg-red-600 absolute right-0 top-0 -m-0.5"></div>
          </Link> */}
          <Link to="/faq" className="text-black no-underline font-bold mx-4">
            FAQ
          </Link>
          <div className="mx-4 hover:text-blue-800 font-semibold cursor-pointer" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
