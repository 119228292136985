import React from "react";
import faqData from "../../components/faq/faq.data.json";

const FAQ = () => {
  return (
    <div>
      <h1 className="my-4 text-2xl SamsungSharpSansBold">Frequently Asked Questions</h1>
      {faqData.map((faq) => (
        <div key={faq.id} className="faq-section m-4">
          <div className="question SamsungSharpSansBold text-lg my-4">Q. {faq.title}</div>
          <div className="answer">
            <b>A.</b> <span dangerouslySetInnerHTML={{ __html: faq.description }}></span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
