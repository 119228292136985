import React, { useState } from "react";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup from "reactjs-popup";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { RemoveTv } from "../services/backend";

const DeviceRemoval = () => {
  const [removeConfirmationText, setRemoveConfirmationText] = useState("");
  const [removeTvModalOpened, setRemoveTvModalOpened] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const isRemoveDisabled = () => {
    return removeConfirmationText?.toLowerCase() !== "remove";
  };

  const closeRemoveTvModal = () => {
    setRemoveTvModalOpened(false);
    setRemoveConfirmationText("");
  };

  const handleCloseConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  const requestRemoveTv = () => {
    RemoveTv().then((res) => {
      closeRemoveTvModal();
      setShowConfirmationDialog(true);
    });
  };

  return (
    <div className="">
      <div onClick={() => setRemoveTvModalOpened(true)} className="cursor-pointer">
        <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
        Request device removal
      </div>

      <Popup modal open={removeTvModalOpened} onClose={closeRemoveTvModal}>
        <div className="modal bg-white p-12 border border-black">
          <FontAwesomeIcon icon={faTimes} className="absolute right-0 top-0 m-5 cursor-pointer" onClick={closeRemoveTvModal} />
          <div className="content width-full text-center">
            Are you sure you want to send the request to remove this device? To confirm that please type the word “Remove” in the box below and press Confirm button.
          </div>
          <div className="actions text-center m-6">
            <input
              value={removeConfirmationText}
              type="text"
              placeholder="Type remove"
              className="py-2 px-3 rounded-md border border-solid border-gray-500"
              id="remove_input"
              onChange={(e) => {
                setRemoveConfirmationText(e.target.value);
              }}
            />
            <button
              className={` ${isRemoveDisabled() ? "cursor-not-allowed" : "cursor-pointer"} w-28 max-w-md mx-4 py-2 px-4 rounded-full no-underline bg-blue-800 text-white`}
              disabled={isRemoveDisabled()}
              onClick={() => requestRemoveTv()}
            >
              Confirm
            </button>
          </div>
        </div>
      </Popup>

      <Popup modal open={showConfirmationDialog} onClose={handleCloseConfirmationDialog}>
        <div className="modal bg-white p-12 border border-black">
          <FontAwesomeIcon icon={faTimes} className="absolute right-0 top-0 m-5 cursor-pointer" onClick={handleCloseConfirmationDialog} />
          <div className="content width-full text-center">
            <div className="pb-2">Thank you !</div>
            Your request has been sent to TV and You support team. Someone will contact you shortly about next steps.
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default DeviceRemoval;
