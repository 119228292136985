import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Login } from "../../services/backend";
import { getParameterByName } from "../../utility";
import global_config from "../../global.config.json";

const UseridLogin = () => {
  const [returnUrl, setReturnUrl] = useState(getParameterByName("return-url"));

  useEffect(() => {
    const expires = new Date(new Date().getTime() + 10 * 60 * 1000); // 10 minute

    Cookies.remove(global_config.session.name);
    Cookies.set(`${global_config.session.name}-return-url`, returnUrl, { expires });

    setTimeout(() => {
      Login("userid");
    }, 100);
  }, []);

  return "";
};

export default UseridLogin;
