import React, { useEffect, useState } from "react";
import { DeleteMessage, FetchMessages, MarkMessageAsRead } from "../../services/backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrashAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import Popup from "reactjs-popup";

const Inbox = (props) => {
  const [messages, setMessages] = useState([]);
  const [messagesToDsiplay, setMessagesToDsiplay] = useState([]);
  const [openedMessage, setOpenedMessage] = useState(null);
  const [displayAllMsgs, setDisplayAllMsgs] = useState(true);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [msgIdToDelete, setMsgIdToDelete] = useState(null);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    FetchMessages().then((res) => {
      setMessages(res);
      setMessagesToDsiplay(res);
    });
  }, []);

  const filterBySearchString = (query) => {
    setSearchString(query);
    if (query === "") return filterUnreadMessages(!displayAllMsgs);
    let selectedMessages = displayAllMsgs ? messages : messages.filter(m => !m.read);
    setMessagesToDsiplay(selectedMessages.filter(m =>
      m.title.toLowerCase().includes(query.toLowerCase()) || m.subject.toLowerCase().includes(query.toLowerCase())));
  }

  const filterUnreadMessages = (filter) => {
    setSearchString("");
    if (filter) {
      setDisplayAllMsgs(false);
      setMessagesToDsiplay(messages.filter(m => !m.read));
    } else {
      setDisplayAllMsgs(true);
      setMessagesToDsiplay(messages);
    }
  }

  const requestDeleteMessage = () => {
    DeleteMessage(msgIdToDelete).then((res) => {
      if (openedMessage?.id === msgIdToDelete) setOpenedMessage(null);
      closeConfirmationDialog();

      FetchMessages().then((res) => {
        setMessages(res);
        setMessagesToDsiplay(res);
      });
    });
  }

  const openConfirmationDialog = (msgId) => {
    setMsgIdToDelete(msgId);
    setDeleteModalOpened(true);
  }

  const closeConfirmationDialog = () => {
    setMsgIdToDelete(null);
    setDeleteModalOpened(false);
  }

  const openMessage = (id) => {
    setOpenedMessage(messagesToDsiplay.find(m => m.id === id));

  }

  useEffect(() => {
    if (openedMessage && !openedMessage.read) markMessageAsRead();
  }, [openedMessage]);

  const markMessageAsRead = () => {
    openedMessage.read = true;
    setMessagesToDsiplay(messagesToDsiplay.map(m => {
      if (m.id === openedMessage.id) m.read = true;
      return m;
    }));
    setMessages(messages.map(m => {
      if (m.id === openedMessage.id) m.read = true;
      return m;
    }));
    MarkMessageAsRead(openedMessage.id).then((res) => { });
  }

  return (
    <div className="inbox-page bg-gray-100 p-4">
      <h1 className="SamsungSharpSansBold">Inbox ({messages.filter(m => !m.read).length})</h1>
      <div className="grid grid-cols-2 gap-2">
        <div className="bg-white p-6 px-0 flex flex-col space-y-4">
          <div className="px-6 flex-shrink flex relative">
            <input value={searchString} onChange={(e) => filterBySearchString(e.target.value)} type="text" placeholder="Search" className="border border-solid border-gray-200 py-2 px-4 rounded-md flex-1" />
            <FontAwesomeIcon icon={faSearch} className="absolute right-6 top-0 p-2" />
          </div>
          <div className="px-12 py-2 flex gap-4 border-2 border-t-0 border-l-0 border-r-0 border-solid border-gray-100">
            <div onClick={() => filterUnreadMessages(false)} className={`${displayAllMsgs && "underline font-bold"} cursor-pointer `}>All</div>
            <div onClick={() => filterUnreadMessages(true)} className={`${!displayAllMsgs && "underline font-bold"} cursor-pointer `}>Unread</div>
          </div>
          <div className="flex-grow">
            {messagesToDsiplay.map((msg) => (
              <div key={msg.id} onClick={() => openMessage(msg.id)}
                className={`${msg.id === openedMessage?.id && "bg-blue-50"} msg-container cursor-pointer px-6 flex flex-row items-center hover:bg-gray-50 py-4 relative border border-t-0 border-l-0 border-r-0 border-solid border-gray-100`}>
                {!msg.read && <div className="border-0 border-l-4 border-solid border-blue-700 h-full absolute -m-6"></div>}

                <div className="flex-grow">
                  <div className="msg-title text-xl font-bold text-blue-700 underline">{msg.title}</div>
                  <div className="msg-subject">{msg.subject}</div>
                </div>

                <div className="msg-date">{msg.created_at}</div>

                <div onClick={() => openConfirmationDialog(msg.id)} className="msg-delete px-2 cursor-pointer hover:text-bold">
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
              </div>
            ))}
            {messagesToDsiplay?.length === 0 && (<div className="p-6 text-center">No messages to show!</div>)}
          </div>
        </div>
        <div className="bg-white p-6 flex flex-col">
          {openedMessage && (
            <>
              <div className="flex justify-between mb-4">
                <div className="flex flex-col gap-2">
                  <div className="text-xl font-bold">{openedMessage.title}</div>
                  <div>{openedMessage.subject}</div>
                </div>
                <div className="flex flex-col gap-1">
                  {openedMessage.created_at}
                  <button
                    onClick={() => openConfirmationDialog(openedMessage.id)}
                    className="bg-transparent flex justify-evenly rounded-full w-24 border border-solid border-black text-black py-3 cursor-pointer">
                    <FontAwesomeIcon icon={faTrashAlt} />
                    Delete
                  </button>
                </div>
              </div>
              <div className="bg-gray-100 p-6 flex-grow">
                {openedMessage.body}
              </div>
            </>
          )}
        </div>
      </div>

      <Popup modal open={deleteModalOpened} onClose={closeConfirmationDialog}>
        <div className="modal bg-white p-12 border border-black">
          <FontAwesomeIcon icon={faTimes} className="absolute right-0 top-0 m-5 cursor-pointer" onClick={closeConfirmationDialog} />
          <div className="content width-full text-center">
            Are you sure you want to delete this message?
          </div>
          <div className="actions text-center m-6">
            <button
              className={`w-28 max-w-md mx-4 py-2 px-4 rounded-full no-underline bg-blue-800 text-white cursor-pointer`}
              onClick={closeConfirmationDialog}
            >
              No
            </button>
            <button
              className={`w-28 max-w-md mx-4 py-2 px-4 rounded-full no-underline bg-blue-800 text-white cursor-pointer`}
              onClick={() => requestDeleteMessage()}
            >
              Yes
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Inbox;
