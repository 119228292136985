import React from "react";
import { render } from "react-dom";
import App from "./App";
import { GlobalProvider } from "./store/global-store";

import "./main.scss";

render(
  <GlobalProvider>
    <App />
  </GlobalProvider>,
  document.getElementById("samsung-portal")
);
