import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Login, __samsung_sso_url } from "../../services/backend";
import global_config from "../../global.config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const login = "/"; // __samsung_sso_url(state);

const Register = () => {
  const [userId, setUserId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSurveyClick = () => {
    alert("REDIRECT TO SURVEY : " + JSON.stringify({ userId }));
  };

  const handleSSO = () => {
    Login("register");
  };

  useEffect(() => {
    const userId = Cookies.get(global_config.session.userId);
    if (userId) {
      setUserId(userId);
      setIsLoggedIn(true);
      window.location.href = `${global_config.config.registration_survey}?userId=${userId}`;
    }
  }, []);

  return (
    <div className="register text-2xl">
      {!isLoggedIn ? (
        <>
          <p>You recently indicated in a member survey that you are interested in joining the Samsung TV and You Community. We are ready to get started and are excited to have you join!</p>
          <p>Please sign into your Samsung Rewards account to begin your registration.</p>
          <div className="inline-block my-4 bg-blue-700 font-semibold no-underline text-white text-2xl border-0 py-3 px-6 rounded-full cursor-pointer" onClick={handleSSO}>
            Click here to login to your Samsung Account
          </div>
        </>
      ) : (
        // `REDIRECT : {{SURVEYLINK}}?userId=${userId}`
        <p>Please wait while we redirect you to the registration survey.</p>
      )}
    </div>
  );
};

export default Register;
