import React from "react";
import { Link } from "react-router-dom";

const DashboardTab = (props) => {
  const { to, active } = props;
  return (
    <Link to={to} className={`dashboard-tab mx-4 py-2 px-4 rounded-full no-underline ${!!active ? "bg-black text-white" : "text-black"}`}>
      {props.children}
    </Link>
  );
};

export default DashboardTab;
