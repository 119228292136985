import axios from "axios";
// import global_config from "../global.config.json";
import { getSessionUser, setSessionUser } from "../utility";
import messages from "./dummy-data/messages.json";
import members from "./dummy-data/members.json";
import televisions from "./dummy-data/televisions.json";
import Cookies from "js-cookie";
import global_config from "../global.config.json";
import uuid from "uuid/v4";

export { axios };
export const basepath = "/api/v1";

// internal functions
const __getRequest = (endpoint, cancelToken) => {
  const bearer = Cookies.get(global_config.session.name);

  if (bearer) {
    const config = {
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      cancelToken,
    };

    return axios.get(endpoint, config).then((res) => res.data);
  }

  return new Promise((res, rej) => {
    Cookies.remove(global_config.session.name);
    rej({
      code: 401,
      error: "UNAUTHORIZED",
      message: "UNAUTHORIZED",
    });
  });
};

const __postRequest = (endpoint, payload, cancelToken) => {
  const bearer = Cookies.get(global_config.session.name);

  if (bearer) {
    const config = {
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      cancelToken,
    };
    return axios.post(endpoint, payload, config).then((res) => res.data);
  }

  return new Promise((res, rej) => {
    Cookies.remove(global_config.session.name);
    rej({
      code: 401,
      error: "UNAUTHORIZED",
      message: "UNAUTHORIZED",
    });
  });
};

const __deleteRequest = (endpoint, cancelToken) => {
  const bearer = Cookies.get(global_config.session.name);

  if (bearer) {
    const config = {
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      cancelToken,
    };
    return axios.delete(endpoint, config).then((res) => res.data);
  }

  return new Promise((res, rej) => {
    Cookies.remove(global_config.session.name);
    rej({
      code: 401,
      error: "UNAUTHORIZED",
      message: "UNAUTHORIZED",
    });
  });
};

const __putRequest = (endpoint, payload = null, cancelToken) => {
  const bearer = Cookies.get(global_config.session.name);

  if (bearer) {
    const config = {
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
      cancelToken,
    };
    return axios.put(endpoint, payload, config).then((res) => res.data);
  }

  return new Promise((res, rej) => {
    Cookies.remove(global_config.session.name);
    rej({
      code: 401,
      error: "UNAUTHORIZED",
      message: "UNAUTHORIZED",
    });
  });
};

// public functions

export const Login = (page) => {
  window.location.href = `${basepath}/login?page=${page}&uuid=${uuid()}`;
};

export const GetUser = () => {
  const endpoint = `${basepath}/user`;
  return __getRequest(endpoint);
};

export const GetTelevisions = () => {
  const endpoint = `${basepath}/televisions`;
  return __getRequest(endpoint);
};

export const GetDeviceCompliance = (deviceId, cancelToken) => {
  const endpoint = `${basepath}/compliance/device?deviceId=${deviceId}`;
  return __getRequest(endpoint, cancelToken);
};

export const GetHouseholdMembers = () => {
  const endpoint = `${basepath}/members`;
  return __getRequest(endpoint);
};

export const SendInvite = (payload) => {
  const endpoint = `${basepath}/invite`;
  // console.log("SendInvite", payload);
  return __postRequest(endpoint, payload);
};

export const RemoveTv = (television_id) => {
  const endpoint = `${basepath}/televisions/${television_id}`;
  return __deleteRequest(endpoint);
};

export const CancelMembership = (household_id, contact_Id) => {
  const endpoint = `${basepath}/membership/${household_id}/${contact_Id}`;
  return __deleteRequest(endpoint);
};

export const UpdateProfile = (payload) => {
  const endpoint = `${basepath}/profile`;
  // console.log("UpdateProfile", payload);
  return __putRequest(endpoint, payload);
};

export const FetchMessages = () => {
  const endpoint = `${basepath}/messages`;
  return __getRequest(endpoint);
};

export const DeleteMessage = (msg_id) => {
  const endpoint = `${basepath}/messages/${msg_id}`;
  return __deleteRequest(endpoint);
};

export const MarkMessageAsRead = (msg_id) => {
  const endpoint = `${basepath}/messages/read/${msg_id}`;
  return __putRequest(endpoint);
};

export const UpdateStreamingDevice = (Id, streaming__c) => {
  const endpoint = `${basepath}/device/attachments`;
  const payload = { Id, streaming__c };
  return __putRequest(endpoint, payload);
};

export const GetRewards = (limit = 5) => {
  const endpoint = `${basepath}/rewards?limit=${limit}`;
  return __getRequest(endpoint);
};
