import global_config from "./global.config.json";

export function isLoggedIn() {
  const user = window.sessionStorage.getItem(global_config.session.name);
  return user && JSON.parse(user);
}

export function getSessionUser() {
  const user = window.sessionStorage.getItem(global_config.session.name);
  return user && JSON.parse(user);
}

export function setSessionUser(user) {
  window.sessionStorage.setItem(global_config.session.name, JSON.stringify(user));
}

export function logout() {
  window.sessionStorage.removeItem(global_config.session.name);
}

export function isValidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isValidPhoneNumber(phoneNumber) {
  // valid numbers
  // XXX-XXX-XXXX
  // XXX.XXX.XXXX
  // XXX XXX XXXX
  const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return re.test(String(phoneNumber).toLowerCase());
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const isEmpty = (value) => {
  return (value || "").trim() === "";
};

export const isNotEmpty = (value) => {
  return (value || "").trim() !== "";
};
