import React, { useContext, useState } from "react";
import questions from "./add-tv-questions.json";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { ROUTE_LIST } from "../../routes";
import { GlobalContext } from "../../store/global-store";

const AddTelevision = () => {
  const { state } = useContext(GlobalContext);
  const [showDialog, setShowDialog] = useState(false);
  const history = useHistory();

  const closeDialog = () => {
    setShowDialog(false);
    history.push(ROUTE_LIST.DASHBOARD);
  };

  const handleSubmit = () => {
    setShowDialog(true);
  };

  return (
    <div className="add-tv-survey flex flex-col h-full">
      <h1 className="flex-shrink">Add a TV</h1>

      <iframe src={`https://registration.tvandyou.tv/survey/selfserve/53b/2109262?hhid=${state?.user?.household_id}`} className="w-full flex-grow border-0"></iframe>

      {/* {questions.map((q) => (
        <div key={q.id} className="question my-4 border-0 border-t border-solid border-gray-500">
          <div className="question-text font-bold my-4">{q.question}</div>
          <div className="answers ml-2">
            {q.answers.map((a) => (
              <label htmlFor={`${q.id}_${a.code}`} key={a.code} className="answer flex flex-row py-1 hover:bg-gray-100 cursor-pointer select-none">
                <input type="radio" id={`${q.id}_${a.code}`} className="mr-2" name={q.id} />
                {a.label}
              </label>
            ))}
          </div>
        </div>
      ))}

      <div className="my-4 py-4 border-0 border-t border-solid border-gray-500">
        <button className="py-2 px-3 bg-blue-700 text-white rounded-full cursor-pointer outline-none focus:outline-none" onClick={handleSubmit}>
          Submit
        </button>
      </div> */}

      <Popup modal open={showDialog} onClose={closeDialog}>
        <div className="modal bg-white p-12 border border-black">
          <FontAwesomeIcon icon={faTimes} className="absolute right-0 top-0 m-5 cursor-pointer" onClick={closeDialog} />
          <div className="content width-full text-center">Thank you. Someone will contact you shortly about next steps.</div>
        </div>
      </Popup>
    </div>
  );
};

export default AddTelevision;
