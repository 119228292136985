import React, { useState } from "react";
import PropTypes from "prop-types";
import { isEmpty, isValidEmail } from "../../utility";

const EmailInput = (props) => {
  const { value, onChange, required = false } = props;
  const [email, setEmail] = useState(value);
  const [valid, setValid] = useState(true);

  const handleOnChange = (e) => {
    const val = e.target.value;
    setEmail(val);
    onChange(val);
    setValid(!!isValidEmail(val) || (!required && isEmpty(val)));
  };

  return (
    <input type="email" value={email} onChange={handleOnChange} className={`${!valid && "border-red-600 focus:border-red-600 bg-red-200"} py-2 px-3 rounded-md border border-solid border-gray-500`} />
  );
};

EmailInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.func),
};

export default EmailInput;
