import React, { useState } from "react";
import { UpdateStreamingDevice } from "../../services/backend";
import streaming_devices from "./streaming-devices.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import picklistStreaming from "../../../picklistValuesGetter/picklists/streaming.json";

const StreamingDevice = (props) => {
  const { tv } = props;
  const [isLoading, setIsLoading] = useState(false);

  const updateStreamingDevice = (Id, streaming__c) => {
    // console.log("updateStreamingDevice", { Id, streaming__c });
    setIsLoading(true);
    UpdateStreamingDevice(Id, streaming__c).then((res) => {
      // console.log(res);
      setIsLoading(false);
    });
  };

  return (
    <div className="relative">
      <select onChange={(e) => updateStreamingDevice(tv.Id, e.target.value)} className="py-2 px-3 border-gray-500 rounded-md" disabled={isLoading} defaultValue={tv.streaming}>
        <option>Select Streaming Device</option>
        {picklistStreaming.map((device) => (
          <option key={device.value} value={device.value}>
            {device.label}
          </option>
        ))}
      </select>
      {isLoading && <FontAwesomeIcon icon={faSpinner} spin pulse className="absolute right-0 mt-2 mr-10" />}
    </div>
  );
};

export default StreamingDevice;
