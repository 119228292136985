import React from "react";
import HomeBanner from "../../components/home-banner/home-banner";
import FAQ from "../../components/faq/faq";

const Home = (props) => {
  return (
    <>
      <HomeBanner />
      <FAQ />
    </>
  );
};

export default Home;
