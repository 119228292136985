/** @jsx jsx */
import { jsx } from "@emotion/react";

import { CircularProgress } from "@mui/material";
import React from "react";
import { css } from "@emotion/react";

export const Loader = () => {
  return (
    <>
      <CircularProgress />
      <span
        css={css`
          margin-left: 1rem;
        `}
      >
        Loading...
      </span>
    </>
  );
};
