import EVENTS from "./events.json";

const reducer = (state, action) => {
  switch (action.type) {
    case EVENTS.UPDATE_USER:
      return { ...state, user: action.payload };

    case EVENTS.UPDATE_LOADING_STATE:
      return { ...state, isLoading: !!action.payload };

    case EVENTS.UPDATE_CONTACT_COMPLIANCE:
      return { ...state, isCompliant: !!action.payload };

    case EVENTS.UPDATE_MESSAGE_SENT:
      return { ...state, msgSent: !!action.payload };

    default:
      return state;
  }
};

export default reducer;
