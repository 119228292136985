import React, { createContext, useReducer } from "react";
import initialState from "./initial-state";
import reducer from "./reducer";

const GlobalContext = createContext(initialState);

const GlobalProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
