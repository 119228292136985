import React, { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { getParameterByName } from "../../utility";
import global_config from "../../global.config.json";
import { ROUTE_LIST } from "../../routes";

const Authorize = () => {
  const history = useHistory();

  useEffect(() => {
    const token = getParameterByName("token");
    const page = getParameterByName("page");
    const userId = getParameterByName("userId");
    // console.log({ token, page, userId });

    if (page === "login") {
      Cookies.set(global_config.session.name, token);
      history.push(ROUTE_LIST.HOME);
      window.location.reload();
    } else if (page === "register") {
      Cookies.set(global_config.session.userId, userId);
      history.push(ROUTE_LIST.REGISTER);
    }
  }, []);

  return "";
};

export default Authorize;
