import React, { useContext } from "react";
import { ROUTE_LIST } from "../../routes";
import { Link } from "react-router-dom";
import MessageForm from "./MessageForm";
import { GlobalContext } from "../../store/global-store";
import dayjs from "dayjs";

const Footer = () => {
  const { state } = useContext(GlobalContext);
  const year = dayjs().format("YYYY");

  return (
    <div className="footer container mx-auto py-4 flex-shrink flex flex-row justify-center border-0 border-t border-solid border-gray-700 relative">
      <div className="flex flex-row">
        <Link to="/terms-of-service" className="mx-2 no-underline text-black">
          Terms of Service
        </Link>
        |
        <a href="https://www.samsung.com/us/account/privacy-policy/" className="mx-2 no-underline text-black" target="_blank">
          Privacy Policy
        </a>
        {/* <Link to="/privacy-policy" className="mx-2 no-underline text-black">
          Privacy Policy
        </Link> */}
        |
        {state.user && (
          <>
            <Link to={ROUTE_LIST.CANCEL_MEMBERSHIP} className="mx-2 no-underline text-black">
              Cancel Membership
            </Link>
            |
          </>
        )}
        <span className="mx-2 text-black">Copyright&copy; {year} Samsung</span>
      </div>
      {/* <MessageForm /> */}
    </div>
  );
};

export default Footer;
