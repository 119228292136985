import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";

const Coregister = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleClick = () => {
    setLoading(true);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = (prop) => {
    setValues({
      ...values,
      [prop]: !values?.[prop],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="coregister bg-gray-200 p-4 md:px-8 md:py-4">
      <h1>Your Membership is verified.</h1>
      <p>
        Please set your Password to complete your enrollment. Rest assured, the protection and safe-keeping of your data is one of our primary concerns and our commitment to your privacy and the
        reasonable use of your data has been independently certified.
      </p>

      <div className="my-4">
        <FormControl sx={{ m: 1, width: "25ch", display: "block" }}>
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            className="bg-white"
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={() => handleClickShowPassword("showPassword")} onMouseDown={handleMouseDownPassword} edge="end">
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
      </div>

      <div className="my-4">
        <FormControl sx={{ m: 1, width: "25ch", display: "block" }}>
          <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showConfirmPassword ? "text" : "password"}
            value={values.confirmPassword}
            onChange={handleChange("confirmPassword")}
            className="bg-white"
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={() => handleClickShowPassword("showConfirmPassword")} onMouseDown={handleMouseDownPassword} edge="end">
                  {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm Password"
          />
        </FormControl>
      </div>

      {/* <div className="my-4">
        Password
        <input type="password" className="block py-2 px-3 border-solid border-gray-400 rounded-md" />
        <small>You must enter a minimum of 8 characters with at least one (1) letter and one (1) number</small>
      </div>

      <div className="my-4">
        Confirm Password
        <input type="password" className="block py-2 px-3 border-solid border-gray-400 rounded-md" />
      </div> */}

      {/* <div className="">
        Security Question
        <select className="block py-2 px-3" name="securityQuestion" id="securityQuestion" required="required">
          <option value="" disabled>
            Make selection...
          </option>
          <option value="87">Who is your favorite artist or musician?</option>
          <option value="89">What is the last name of your best friend?</option>
          <option value="91">What is your favorite color of car?</option>
          <option value="93">Where did you meet your spouse/partner?</option>
          <option value="95">What is your favorite insect?</option>
        </select>
      </div>

      <div className="my-4">
        Security Answer
        <input type="text" className="block py-2 px-3 border-solid border-gray-400 rounded-md" />
      </div> */}

      {/* <button className="my-4 py-2 px-4 bg-blue-700 text-white rounded-md border-0 text-lg cursor-pointer">Submit</button> */}
      <LoadingButton onClick={handleClick} endIcon={<SendIcon />} loading={loading} loadingPosition="end" variant="contained">
        Submit
      </LoadingButton>
    </div>
  );
};

export default Coregister;
