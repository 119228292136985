import React from "react";
import toshtml from "./tos.html";

const TermsOfService = () => {
  return (
    <div>
      <h1 className="my-4 text-2xl SamsungSharpSansBold">Samsung Panel Terms and Conditions </h1>
      <div className="" dangerouslySetInnerHTML={{ __html: toshtml }}></div>
    </div>
  );
};

export default TermsOfService;
