import React, { useContext } from "react";
import { GlobalContext } from "../store/global-store";

const WelcomeUserInfo = () => {
  const { state } = useContext(GlobalContext);

  return <div className="welcome-header SamsungSharpSansBold text-black text-2xl">Welcome, {state.user?.FirstName}!</div>;
};

export default WelcomeUserInfo;
