/** @jsx jsx */
import { jsx } from "@emotion/react";

import { faEnvelope, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { global } from "../../global.config.json";
import { GetHouseholdMembers, SendInvite } from "../../services/backend";
import { GlobalContext } from "../../store/global-store";
import { Loader } from "../Loader";
import "./household-members.scss";

const HouseholdMembers = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const [members, setMembers] = useState([]);
  const [registeredMembers, setRegisteredMembers] = useState([]);
  const [isMaxMembers, setIsMaxMembers] = useState(false);
  const [email, setEmail] = useState("");
  const [additionalMsg, setAdditionalMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleInviteSendClick = () => {
    const payload = { Email: email, referral_message: additionalMsg, referred_by_id: state?.user?.Id };
    // console.log("handleInviteSendClick", payload);
    setIsLoading(true);
    SendInvite(payload)
      .then((res) => {
        setEmail("");
        setAdditionalMsg("");
        setIsLoading(false);
        setShowInviteDialog(true);
        setErrors([]);
        getHHMembers();
      })
      .catch((err) => {
        setEmail("");
        setAdditionalMsg("");
        setIsLoading(false);
        // setErrors([err.response?.data?.message]);
        if (err.response?.data?.message === "HOUSEHOLD_FULL") {
          setErrors(["Your household is full. Please contact the Samsung TV and You member support team."]);
        }
      });
  };

  const handleDialogClose = () => {
    setShowInviteDialog(false);
  };

  const getHHMembers = () => {
    setIsLoadingMembers(true);
    GetHouseholdMembers()
      .then((members) => {
        setMembers(members);
        setIsLoadingMembers(false);
        setErrors([]);
      })
      .catch((err) => {
        setIsLoadingMembers(false);
        setErrors([err]);
      });
  };

  useEffect(() => {
    getHHMembers();
  }, []);

  useEffect(() => {
    // console.log("members:", members);
    setRegisteredMembers(members.filter((member) => [2, 3].includes(member.membership_status)));
  }, [members]);

  useEffect(() => {
    // console.log({ registeredMembers });
    setIsMaxMembers(registeredMembers.length >= (global?.max_members || 4));
  }, [registeredMembers]);

  // useEffect(() => {
  //   console.log({ registeredMembers, isMaxMembers });
  // }, [isMaxMembers]);

  if (isLoadingMembers) {
    return <Loader />;
  }

  return (
    <div className="flex flex-row bg-white w-full">
      <div className="household-members p-10 pl-6 pr-12 w-1/2 text-2xl text-center text-white flex-1">
        Active household members include:
        <div className="members-list text-left max-w-max m-auto my-4 text-lg">
          {isLoadingMembers && <FontAwesomeIcon icon={faSpinner} spin pulse className="text-4xl text-white z-50" />}
          {!isLoadingMembers &&
            // 2023-11-18 -- only show active members
            members
              .filter((member) => [3].includes(member.membership_status))
              .map((member) => (
                <div key={member.Id} className="member my-2">
                  <FontAwesomeIcon icon={faEnvelope} className="bg-transparent mr-5" />
                  {member.FirstName} {member.LastName}
                </div>
              ))}
        </div>
        {/* 2023-11-18 -- only show active members */}
        {/* {members.filter((member) => member.membership_status === 1).length > 0 && (
          <>
            <hr className="opacity-20" />
            Invited but not yet registered:
            <div className="members-list text-left max-w-max m-auto my-4 text-lg">
              {!isLoadingMembers &&
                members
                  .filter((member) => member.membership_status === 1)
                  .map((member) => (
                    <div key={member.Id} className="member my-2">
                      <FontAwesomeIcon icon={faEnvelope} className="bg-transparent mr-5" />
                      {member.Email}
                    </div>
                  ))}
            </div>
          </>
        )} */}
      </div>

      <div className="invite-container p-12 text-center flex flex-col items-center flex-1">
        {errors.length > 0 && (
          <div className="errors">
            {errors.map((err) => (
              <div key={err} className="error px-4 py-2 mb-2 bg-red-700 text-white">
                {err}
              </div>
            ))}
          </div>
        )}
        {isLoadingMembers && <>Loading...</>}
        {!isLoadingMembers && (
          <div className="mb-12">
            New users can be added in the TV and You app under Settings/TV and You Users. If you wish to remove a user please email <a href="mailto:support@tvandyou.tv">support@tvandyou.tv</a>.
          </div>
        )}
        {/* {!isLoadingMembers && isMaxMembers && <div className="mb-12">You already have {global.max_members} household members registered, which is the maximum for this program.</div>}
        {!isLoadingMembers && !isMaxMembers && (
          <>
            <div className="mb-12">
              All adult members of your household can join and earn rewards. If you have additional household members you'd like to add please share their emails in the box below.
            </div>
            <input
              type="email"
              id="login-email"
              placeholder="Enter email"
              className="mb-6 w-1/2 bg-transparent border-0 border-b border-solid border-black placeholder-black placeholder-opacity-60 py-2 outline-none focus:outline-none text-black text-md text-center"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              className="bg-gray-100 p-4 text-left mb-3 w-full border-0"
              placeholder="Enter an optional message to them here. This is your chance to explain why it's a great idea to join the TV and You community."
              value={additionalMsg}
              onChange={(e) => setAdditionalMsg(e.target.value)}
            ></textarea>

            <button
              className={` ${!isValidEmail(email) ? "cursor-not-allowed" : "cursor-pointer"} w-28 max-w-md mx-4 py-2 px-4 rounded-full no-underline bg-blue-800 text-white`}
              disabled={!isValidEmail(email)}
              onClick={handleInviteSendClick}
            >
              Invite
            </button>
          </>
        )} */}
      </div>

      {/* <Popup modal open={showInviteDialog} onClose={handleDialogClose}>
        <div className="modal bg-white p-12 border border-black">
          <FontAwesomeIcon icon={faTimes} className="absolute right-0 top-0 m-5 cursor-pointer" onClick={handleDialogClose} />
          <div className="content width-full text-center">
            <div className="pb-2">Thank you !</div>
            Your request has been sent to TV and You support team.
          </div>
        </div>
      </Popup> */}

      {isLoading && (
        <Loader />
        // <div className="loading-indicator fixed top-0 right-0 left-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
        //   <FontAwesomeIcon icon={faSpinner} spin pulse className="text-4xl text-white z-50" />
        // </div>
      )}
    </div>
  );
};

export default HouseholdMembers;
