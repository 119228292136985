import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { axios, GetDeviceCompliance } from "../services/backend";
import { GlobalContext } from "../store/global-store";
import moment from 'moment';

let complianceTimer;

const DeviceCompliance = (props) => {
  const { onUpdate } = props;
  const { state } = useContext(GlobalContext);
  const [isCompliant, setIsCompliant] = useState(null);
  const [device, setDevice] = useState(null);
  const source = axios.CancelToken.source();

  const pollCompliance = (props) => {
    clearTimeout(complianceTimer);
    GetDeviceCompliance(device?.device_id, source.token).then((deviceData) => {
      const now = moment();
      const lastReceivedHeartbeatAt = moment(deviceData?.lastReceivedHeartbeatAt);
      const daysDiff = now.diff(lastReceivedHeartbeatAt, 'days');
      const isP24 = daysDiff <= 1;
      // console.log('pollCompliance::GetDeviceCompliance:', {
      //   now: now.format('YYYY-MM-DD'),
      //   lastReceivedHeartbeatAt: lastReceivedHeartbeatAt.format('YYYY-MM-DD'),
      //   daysDiff,
      //   isP24
      // });
      setIsCompliant(isP24);
      onUpdate(isP24, device?.Name);
      if (device && !["localhost", "dev.tvandyou.tv"].includes(window.location.hostname)) {
        complianceTimer = setTimeout(() => {
          pollCompliance();
        }, 10000);
      }
    });
  };

  const renderGood = () => {
    return (
      <>
        <div className="w-4 h-4 rounded-full bg-green-500 mr-2"></div>
        Working fine
      </>
    );
  };

  const renderNotGood = () => {
    return (
      <>
        <div className="w-4 h-4 rounded-full bg-red-500 mr-2"></div>
        Meter offline
        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2 text-yellow-500" />
      </>
    );
  };

  useEffect(() => {
    setDevice(props.device);
  }, [props.device]);

  useEffect(() => {
    if (device) {
      pollCompliance();
    }
    return () => {
      source.cancel("Component got unmounted");
    };
  }, [device]);

  return (
    <div className="flex flex-row">
      {isCompliant && renderGood()}
      {isCompliant !== null && !isCompliant && renderNotGood()}
      {isCompliant === null && "..."}
    </div>
  );
};

DeviceCompliance.propTypes = {
  device: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

export default DeviceCompliance;
