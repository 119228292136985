import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import WelcomeUserInfo from "../../components/WelcomeUserInfo";
import PlayIcon from "../../assets/images/membership-play.svg";
import PhoneIcon from "../../assets/images/membership-phone.svg";
import BagsIcon from "../../assets/images/membership-bags.svg";
import { CancelMembership as RequestCancelMembership } from "../../services/backend";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ROUTE_LIST } from "../../routes";
import { GlobalContext } from "../../store/global-store";
import Cookies from "js-cookie";
import global_config from "../../global.config.json";
import EVENTS from "../../store/events.json";

export default function CancelMembership(props) {
  const { state, dispatch } = useContext(GlobalContext);
  const [modalOpened, setModalOpened] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [showFinalMessage, setShowFinalMessage] = useState(false);
  const [showNoDialog, setShowNoDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const isConfirmDisabled = () => {
    return confirmationText?.toLowerCase() !== "cancel";
  };

  const closeModal = () => {
    setConfirmationText("");
    setModalOpened(false);
  };

  const cancelMembership = () => {
    setIsLoading(true);
    closeModal();
    const household_id = state?.user?.household_id_number;
    const contact_id = state?.user?.Id;
    RequestCancelMembership(household_id, contact_id).then((res) => {
      setShowFinalMessage(true);
      setIsLoading(false);
      Cookies.remove(global_config.session.name);
      dispatch({
        type: EVENTS.UPDATE_USER,
        payload: null,
      });
      history.push(ROUTE_LIST.HOME);
    });
  };

  const handleBtnNo = () => {
    // history.push(ROUTE_LIST.DASHBOARD);
    setShowNoDialog(true);
  };

  const handleShowNoDialog = () => {
    setShowNoDialog(false);
    history.push(ROUTE_LIST.DASHBOARD);
  };

  useEffect(() => {
    console.log(state?.user);
  }, [state?.user]);

  return (
    <div className="bg-gray-100 p-6 pb-40">
      <WelcomeUserInfo />

      <div className="w-4/5 m-auto mt-12">
        <div className="text-center m-auto mb-4">
          Seems you want to cancel your membership. <br />
          Look how many great things you get by being a member of the TV and You Community.
          <br />
          Are you absolutely sure?
        </div>

        <div className="text-center mb-10">
          <button className="m-2 bg-transparent rounded-full w-28 border border-solid border-black text-black py-3 mt-3 cursor-pointer" onClick={handleBtnNo}>
            No
          </button>
          <button onClick={() => setModalOpened(true)} className="m-2 bg-transparent rounded-full w-28 border border-solid border-black text-black py-3 mt-3 cursor-pointer">
            Yes
          </button>
        </div>

        <div className="flex gap-10">
          <div className="bg-white p-6 pb-20 text-center flex flex-1 flex-col items-center gap-10">
            <img src={PlayIcon} />
            3000 Samsung Rewards points each month per participating adult just for letting us know when you are watching.
          </div>
          <div className="bg-white p-6 pb-20 text-center flex flex-1 flex-col items-center gap-10">
            <img src={PhoneIcon} />
            1000 Samsung Rewards points each month per tablet that is on and connected to a unique TV.
          </div>
          <div className="bg-white p-6 pb-20 text-center flex flex-1 flex-col items-center gap-10">
            <img src={BagsIcon} />
            Your points are transferred to your Samsung Rewards account automatically each month where you can spend them however you choose.
          </div>
        </div>
      </div>

      <Popup modal open={modalOpened} onClose={() => closeModal()}>
        <div className="modal bg-white p-12 border border-black">
          <FontAwesomeIcon icon={faTimes} className="absolute right-0 top-0 m-5 cursor-pointer" onClick={() => closeModal()} />
          <div className="content width-full text-center">
            Are you sure you want to cancel your membership? To confirm that please type the word “Cancel” in the box below and press Confirm button.
          </div>
          <div className="actions text-center m-6">
            <input
              value={confirmationText}
              type="text"
              placeholder="Type cancel"
              className="py-2 px-3 rounded-md border border-solid border-gray-500"
              id="remove_input"
              onChange={(e) => {
                setConfirmationText(e.target.value);
              }}
            />
            <button
              className={` ${isConfirmDisabled() ? "cursor-not-allowed" : "cursor-pointer"} w-28 max-w-md mx-4 py-2 px-4 rounded-full no-underline bg-blue-800 text-white`}
              disabled={isConfirmDisabled()}
              onClick={() => cancelMembership()}
            >
              Confirm
            </button>
          </div>
        </div>
      </Popup>

      <Popup modal open={showNoDialog} onClose={handleShowNoDialog}>
        <div className="modal bg-white p-12 border border-black">
          <FontAwesomeIcon icon={faTimes} className="absolute right-0 top-0 m-5 cursor-pointer" onClick={handleShowNoDialog} />
          <div className="content width-full text-center">
            We are so happy you decided to remain in the TV and You community! If there is any way we can make your experience better, let us know by emailing to{" "}
            <a href="mailto:support@tvandyou.tv">support@tvandyou.tv</a>.
          </div>
        </div>
      </Popup>

      <Popup modal open={showFinalMessage} onClose={() => setShowFinalMessage(false)}>
        <div className="modal bg-white p-12 border border-black">
          <FontAwesomeIcon icon={faTimes} className="absolute right-0 top-0 m-5 cursor-pointer" onClick={() => setShowFinalMessage(false)} />
          <div className="content w-full text-center">
            We have received your request to cancel membership. Please check your email for instructions on returning your tablets.
            <div className="my-4">
              <Link to={ROUTE_LIST.CANCEL_MEMBERSHIP_SURVEY}>Please let us know why you decided to cancel.</Link>
            </div>
          </div>
        </div>
      </Popup>

      {isLoading && (
        <div className="loading-indicator fixed top-0 right-0 left-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center" style={{ zIndex: 999 }}>
          <FontAwesomeIcon icon={faSpinner} spin pulse className="text-4xl text-white z-50" />
        </div>
      )}
    </div>
  );
}
