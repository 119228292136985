import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { getParameterByName } from "../../utility";
import global_config from "../../global.config.json";

const Userid = () => {
  const [userId, setUserId] = useState(getParameterByName("userId"));
  const [countryCode, setCountryCode] = useState(getParameterByName("country_code"));

  useEffect(() => {
    const returnUrl = Cookies.get(`${global_config.session.name}-return-url`);
    window.location.href = `${returnUrl}&ssid=${userId}&country_code=${countryCode}`;
  }, []);

  return "";
};

export default Userid;
